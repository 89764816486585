.login-page {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Jost", sans-serif;
  background: linear-gradient(320deg, #c1dbf2, #ffc3e1, #63b1dd);
  background-size: 600% 600%;
  -webkit-animation: gradient 33s ease infinite;
  -moz-animation: gradient 33s ease infinite;
  animation: gradient 33s ease infinite; 
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}

@-webkit-keyframes gradient {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}
@-moz-keyframes gradient {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}
@keyframes gradient {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}

.login-main-container{
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  width: 70vw;
  height: 87vh;
}

.form{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 4rem;
}

.login-input, .login-select {
  width: 15vw;
  padding: 0.3rem 0;
}

.login-input-login {
  width: 25vw;
  padding: 0.3rem 0;
}

.login-input label, .login-select label, .login-input-login label {
  color: #de66a3 ;
}

.MuiPaper-root{  
  width: 15vw;

}

.MuiList-root,.MuiList-root li{  
white-space: wrap;
}

.gdpr-sign-up {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.gdpr-sign-up-checkbox {
  margin: 0 0.6rem;
  accent-color: #de66a3;
  transform: scale(130%);
  display: flex;
  align-content: center;
  justify-content: center;
}

.gdpr-sign-up-link {
  color: #12659f;
}

.gdpr-sign-up p{
  color:black;
}

.button-rep-signup{
  display: none !important;
}

.button  {
  width: 20vw;
  height: 6vh;
  margin: 0 auto;
  justify-content: center;
  display: block;
  color: #fff;
  background-color:#de66a3;
  font-size: 1em;
  font-weight: bold;
  outline: none;
  border: none;
  border-radius: 1rem;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.button:hover {
  background-color: #bf578b;
}

.login-main-container button.hidden{
  background-color: transparent;
  border: 0.1rem solid white;
  margin-top: 1rem;
}

.login-main-container button.hidden:hover{
  border: 0.1rem solid #ffa0d0;
  color:#ffa0d0;

}

.signup-form button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.signup-form button:disabled:hover,
button[disabled]:hover {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.forgot-pass{
  margin-top: 2vh;
  width: 12vw;
  height: 4vh;
}

.bigger-label{
z-index: 2;
position: relative;
color: #0e4a74;
font-size: 2.3em;
justify-content: center;
display: flex;
font-weight: bold;
}

.signup-container .bigger-label{
  padding: 3rem 0 2rem 0;

}

.login-container .bigger-label{
  padding: 0 0 2rem 0;
  text-align: center;
}

.password-reset-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle-panel h1{
  z-index: 0;
position: relative;
color: white;
font-size: 2.3em;
justify-content: center;
display: flex;
font-weight: bold;
}

.signup-container, .login-container
{
  position: absolute;
  top: 0;
  transition: all 0.7s ease-in-out;  

}

.signup-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:2rem;
}
.signup-left, .signup-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-right{
  margin-top: -2.5rem;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  border-radius: 1rem;
}

.signup-container{
  left: 0;
  width: 50%;
  z-index: 2;
  transition: all 0.4s ease-in-out;

}

.login-container{
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  transition: all 0.4s ease-in-out;

}

.login-main-container.sign-up-mode .signup-container
{
  transform: translateX(100%);
  opacity: 0;
}


.login-main-container.sign-up-mode .login-container{
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.7s;
}

@keyframes move{
  0%, 49.99%{
      opacity: 0;
      z-index: 1;
  }
  50%, 100%{
      opacity: 1;
      z-index: 5;
  }
}

.toggle-container {
  position: absolute;
  top: 0;
  left: 55%;
  width: 45%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 1rem 1rem 100px;
  z-index: 1000;
}
.login-main-container.sign-up-mode .toggle-container{
  transform: translateX(-123%);
  border-radius: 1rem 150px 100px 1rem;
}


.toggle{
  height: 100%;
  background: linear-gradient(to right,#41a0cc, #12659f);
  color: #fff;
  position: relative;
  left: -100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.login-main-container.sign-up-mode .toggle{
  transform: translateX(50%);
  
} 

.toggle-panel{
  position: absolute;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle-left{
  transform: translateX(-200%);
}

.login-main-container.sign-up-mode .toggle-left{
  transform: translateX(0);
}

.toggle-right{
  right: 0;
  transform: translateX(0);
}

.login-main-container.sign-up-mode .toggle-right{
  transform: translateX(200%);
}

@media only screen and (max-width: 600px) {
  

  .login-input, .login-select {
    width: 80vw; 
  }

  .toggle-container {
    left: 0;
    width: 100%; 
    height: 50%;
    border-radius: 0; 
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

  }



  .toggle {
    left: 0;
    width: 100%;
    transform: translateY(0); 
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  
  }

  .toggle-panel {
    width: 100%;
    padding: 0 ; 
    transform: translateY(0); 
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  

  }

  .signup-form{
    flex-direction: column;
  }

  .signup-container{
    width: 100%;
    height: 100%;
    top:40vh;
    overflow-x: scroll;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

  }

  .login-main-container{
    width: 90%;
    margin-bottom: 50vh;
    margin-top: 5vh;
    transition: all 0.5s ease-in-out;

  }

  .button-rep-signup{
    display: block !important;
  }

  .button-signup{
    display: none;
  }
  
  .button{
    width: 40vw;
    height: 6vh;
    margin-bottom: 2rem;
  
  }

  .login-container{
    width: 100%;
    transition: all 0.4s ease-in-out;

  }

  .login-main-container.sign-up-mode{
    margin-bottom: 25vh;
  }

  .login-main-container.sign-up-mode .login-container {
    transform: translateY(20%);
  }

  .login-main-container.sign-up-mode .signup-container
{
  transform: translateY(100%);

  display: none;
}

.login-main-container.sign-up-mode .toggle{
  transform: translateY(50%);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  
} 

.toggle-left{
  transform: translateY(200%);

}

.login-main-container.sign-up-mode .toggle-left{
  transform: translateY(-25%);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;


}

.toggle-right{
  right: 0;
  transform: translateY(0);

}

.login-main-container.sign-up-mode .toggle-right{
  transform: translateY(200%);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;


}

.login-main-container.sign-up-mode .toggle-container {
  transform: translateY(-50%);
  border-radius:0;
  height: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;


}

.login-form {
  height: 60vh;
  margin-top: 25vh;
}

.button:hover {
  background-color:#de66a3;
}

.login-main-container button.hidden:hover{
  border: 0.1rem solid white;
  color: white;
}

.login-input-login {
  width: 80vw;
}

}


