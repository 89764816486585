.page-countdown {
    height: 100vh;
    width: 100vw;
    background-color:  #63B2DE;
    /* background-color:  #66beea; */
    --dark-accent: #529cc1
}

.countdown-logo{
    width: 18rem;
    align-self: center
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;700&display=swap");
.countdown__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.countdown-titlu{
    color: white;
}

.countdown__container .countdown__card {
    width: 20%;
}

.countdown__container .countdown__card .countdown__card__bg {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    background-color: white;
    -webkit-box-shadow: 0px 8px 0px 1px #44AAD8;
    box-shadow: 0px 8px 0px 1px #f1f1f5;
    border-radius: 10px;
    -webkit-transition: all 0.175s linear;
    transition: all 0.175s linear;
}

.countdown__container .countdown__card .countdown__card__bg::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1vh);
    width: 6px;
    height: 12px;
    border-radius: 0 15px 15px 0;
    background-color: var(--dark-accent);
    z-index: 10;
}

.countdown__container .countdown__card .countdown__card__bg::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 1vh);
    width: 6px;
    height: 12px;
    border-radius: 15px 0 0 15px;
    background-color: var(--dark-accent);
    z-index: 10;
}

.countdown__container .countdown__card .countdown__card__bg .countdown__card__number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 8vw;
    color: #e065a4;
}

.countdown__container .countdown__card .countdown__card__bg .countdown__card__number::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #3babda80;
    opacity: 0.35;
    z-index: 10;
}

.countdown__container .countdown__card__label {
    padding-top: 1.5rem;
    text-transform: uppercase;
    font-size: 1.5vw;
    font-weight: bold;
    letter-spacing: 5px;
    color: #e065a4;
}

.rotate {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
}

.social-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 10rem;
}

.social-links .icon {
    list-style: none;
}

.social-links .icon:hover svg path {
    fill: #ffbedf;
}

.social-links .icon svg path {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.attribution {
    text-align: center;
    padding: 2rem;
}

.attribution a {
    color: #8486a9;
    letter-spacing: 1px;
}

.app-container {
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.container img{
    height: 30vh;
    object-fit: contain;
}

.container main {
    /*-webkit-box-flex: 1;*/
    /*-ms-flex: 1 1 auto;*/
    /*flex: 1 1 auto;*/
    min-width: 350px;
    width: 75%;
    max-width: 1100px;
    height: fit-content;
    max-height: 60vh;
    margin: 0 auto 0;
    text-align: center;
    padding: 6rem 0 0;
}

.container main h2 {
    text-align: center;
    font-size: calc(0.75rem + 1vw);
    letter-spacing: 8px;
    text-transform: uppercase;
    padding-bottom: 4rem;
}
/*# sourceMappingURL=app.css.map */