.modal {
    position: absolute;
    top:20rem;
    left: 13rem;
    width: 30%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-main {
    position: relative;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    display: flex ;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1rem;
  }
  
  .display-none {
    display: none;
  }
  
  .display-block {
    display: block;
  }
  
  .modal-main button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  
  .modal-main img {
    max-width: 100%;
    height: auto;
  }
  

  .modal-main::after {
    display: block; width: 0; content: "";
    border: 1.5rem solid transparent;
  }
   
  
  .modal-main.up::after {
    border-bottom-color: #ffffff;
    border-top: 0;
  }
  

  .modal-main::after { 
    position: absolute; 
}

  .modal-main.up::after {
    top: -1.5rem; 
    left: calc(50% - 1.5rem);
  }

  @media only screen and (max-width: 1024px) {

    .modal{
        top: 11rem;
        left: 0.5rem;
        width: 95%;
    }

    .modal-main{
        height: 30vh;;
    }

    .modal-main::after {
        border: 1rem solid transparent;
      }

      .modal-main.up::after {
        top: -1rem; 
        left: calc(50% - 1rem);
      }
  }

  @media only screen and (min-width: 389px) and (max-width: 541px) {

    .modal{
        top: 14rem;
    }
  }

  @media only screen and (min-width: 542px) and (max-width: 1023px) {
    .modal { 
        top: 28rem; 
        left: 1.5rem; 
        width: 50%; 
    }
  }

  
  @media only screen and (min-width: 1024px) and (max-width: 1280px)  {
    .modal { 
        top: 18rem; 
        left: 1.5rem; 
        width: 50%; 
    }
  }
  
