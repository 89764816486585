@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');


.eventSelectorContainer{
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-radius: 15px;
    padding: 0.35rem;
    background-color: #573B8A;
    color: #f1f1f1;
    font-weight: bold;
}

.mortiiLui{
    width: 100%;
}

.eventLabel{
    padding-right: 0.4rem;
}