.edit-container{
    width: 95%;
    height: 100px;
    display: flex;
    flex-direction: column;

}

.title-container span{
font-weight: bold;
    margin-left: 8px;
}
.title-container {
    display: flex;
    flex-basis: fit-content;
    flex-grow: 0;
    align-items: center;
}

.input-wrapper {
    margin-top: 8px;
   flex-grow: 1;
}
.input-wrapper span{
   margin-left: 16px;
}
.edit-wrapper {
    width: 100%;
    display: flex;
    flex-grow: 1;
}

.button-wrapper{
    width: 10%;
    min-width: 50px;
    flex-grow: 0;
}