nav{
    position:fixed;
    top:0;
    left:0;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    width:15%;
    min-height: 95vh;
    max-height: 100%;
    background-color:#c1dbf2;
    padding:2rem 1rem 1rem 1rem;
    transition: transform 1s;
    z-index: 10;
}

.navbar{
    transform: translateX(-100%);
}

.nav-btn{
    position: absolute;
    transform: translateX(38px);
    top: 20px;
    right: 0;
    width:40px;
    height: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#c1dbf2;
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    color:#221e1f;
}

.logo{
    display: block;
    width: 100px;
    margin: auto;
    background:transparent;
}

.logo img{
    display: block;
    width: 100%;
    height: 100%;
}

.nav-link{
    display: flex;
    align-items: center;
    color:#221e1f;
    text-decoration: none;
    padding: 10px 1rem;
    border-radius: 5px;
    margin-bottom: 5px;
}

.nav-link:hover{
    background-color:#eda3c8;
    color: #333333;
}

.nav-link span{
    margin-left: 10px;
}

.nav-top{
    margin-top: 10rem;
}

.mobile-nav{
    z-index: 2;
    background-color:#c1dbf2;
    width: 100%;
    height:40px;
    display: none;
    position: fixed;
}

.mobile-nav-btn{
    color: #de66a3;
    background: transparent;
    outline: none;
    border: none;
    margin: 0 10px;
    width:50px

}

.links{
    padding-top: 80px;
}

@media only screen and (max-width: 768px){
    nav{
        width: 40%;
        padding: 10px;
    }
}

@media only screen and (max-width: 450px){
    .mobile-nav{
        display: flex;
    }
    nav{
        width: 70%;
        top: 38px;
        min-height: calc(100vh - 38px);
    }

    .nav-btn{
        display: none;
    }
}