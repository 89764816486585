.gdpr{
    
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
 
}
a{
    color: white;
}
.regulament{
    display: block;
    width: 60vw;
    text-align: justify;
    padding: 2vw;
}

@media only screen and (max-width:821px)
{
    
   
    h2{
        text-align: center;
    }

    .regulament{
        width: 40vh;
    }

}